import { jsonp } from '@ifeng-fe/ui_base';
import { formatImage, formatUrl } from '@ifeng-fe/public_method';

// apiUrl为webpack注入的全局变量
/* eslint-disable */
let apiBaseUrl = apiUrl;

/* eslint-enable */

if (typeof window !== 'undefined' && /\.shank\.ifeng\.com/.test(window.location.hostname)) {
    apiBaseUrl = '/api';
}

export const getGongyiVideoData = async keys => {
    const res = await jsonp(`${apiBaseUrl}/getVideoData/${keys}/getGongyiVideoData1`, {
        jsonpCallback: 'getGongyiVideoData1',
        cache: false,
    });

    return res.data;
};

// 调查
export const getSurveyData = async id => {
    const data = await jsonp('//sv.ifeng.com/index.php/survey/getdata', {
        data: {
            id,
        },
        jsonpCallback: 'getSurveyData',
        cache: false,
    });

    // 下面代码有兼容性问题?
    // console.log(data)
    // const requestUrl = `sv.ifeng.com/index.php/survey/getdata?id=${id}&callback=getSurveyData`;

    // data = schemaCheck(data, 'surveyDataSchema', requestUrl);
    // console.log(data)
    // const requestUrl = `//survey.news.ifeng.com/api/getaccumulatorweight?format=js&key=${vid}&callback=getaccumulator`;

    // data = schemaCheck(data.data, 'voteNumSchema', requestUrl);

    return data ? data : null;
};
