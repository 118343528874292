import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Ad from '@ifeng-fe/ui_pc_ad';
import NoSSR from 'react-no-ssr';

/**
 * for this page
 */
// titles
import YiGuanZhu from './titles/yiGuanZhu';
import YiChuangXin from './titles/yiChuangXin';
import YiXingDong from './titles/yiXingDong';
import YiYanTang from './titles/yiYanTang';
import YiYingXiang from './titles/yiYingXiang';
import XingDongZhe from './titles/xingDongZhe';
import XianFeng from './titles/xianfeng';
import ShaLong from './titles/shaLong';
import YiXing from './titles/yiXing';
import LianMeng from './titles/lianMeng';
import League from './titles/league';

import TopSlide from './topSlide';
import News from './news';
import Innovation from './innovation';
import Action from './action';
import Survey from './survey';
import Photo from './photo';
import ActionLeague from './actionLeague';

import VideoBox from './videoBox';

import NewsItem from '../../../components/newsItem';
import NewsItemYyt from '../../../components/newsItemYyt';
import NewsItemYxyy from '../../../components/newsItemYxyy';

class Content extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
        ads: PropTypes.object,
    };

    render() {
        // console.log('Content=', this.props);

        const { ads } = this.props;
        const {
            topSlide,
            yiGuanZhu,
            news,
            yiChuangXin,
            innovation,
            yiXingDong,
            action,
            survey,
            yiYanTang,
            comment,
            yiYingXiang,
            photo,
            video,
            xingDongZhe,
            actionLeague,
            xianFeng,
            interview,
            shaLong,
            salon,
            yiXing,
            star,
            lianMeng,
            league,
        } = this.props.content;
        /**
         * 组件分发数据
         */

        return (
            <div className={styles.Content}>
                {/* 顶部轮播 */}
                <div className={styles.topSlide}>
                    <Chip id="60008" type="recommend" title="公益首页-轮播图" groupName="正文" content={topSlide}>
                        <TopSlide ad={ads.topSlideAd} />
                    </Chip>
                </div>
                <div className={styles.ad_m_1}>
                    <Ad content={ads.fullColAd1} />
                </div>
                <NoSSR>
                    <Chip id="30011" type="static" title="公益首页-益关注" groupName="正文" content={yiGuanZhu}>
                        <YiGuanZhu />
                    </Chip>
                </NoSSR>
                <Chip id="60009" type="recommend" title="公益首页-益关注推荐" groupName="正文" content={news}>
                    <News ad={ads.ygzAd} />
                </Chip>
                <NoSSR>
                    <Chip id="30012" type="static" title="公益首页-益创新" groupName="正文" content={yiChuangXin}>
                        <YiChuangXin />
                    </Chip>
                </NoSSR>
                <div className={`${styles.container} clearfix`}>
                    <Chip id="60010" type="recommend" title="公益首页-益创新推荐" groupName="正文" content={innovation}>
                        <Innovation />
                    </Chip>
                    <div className={styles.ad_m_2}>
                        <Ad content={ads.rectangle1} />
                    </div>
                </div>
                <div className={styles.container}>
                    <NoSSR>
                        <Chip
                            id="30013"
                            type="static"
                            title="公益首页-益行动益调查"
                            groupName="正文"
                            content={yiXingDong}>
                            <YiXingDong />
                        </Chip>
                    </NoSSR>
                </div>
                <div className={`${styles.container} clearfix ${styles.h860}`}>
                    <Chip id="60011" type="recommend" title="公益首页-益行动推荐" groupName="正文" content={action}>
                        <Action ad={ads.yxdAd} />
                    </Chip>
                    <Chip id="45008" type="struct" title="公益首页-益调查" groupName="正文" content={survey}>
                        <Survey />
                    </Chip>
                </div>
                <div className={styles.ad_m_3}>
                    <Ad content={ads.fullColAd2} />
                </div>
                <div className={styles.container}>
                    <NoSSR>
                        <Chip id="30014" type="static" title="公益首页-益言堂" groupName="���文" content={yiYanTang}>
                            <YiYanTang />
                        </Chip>
                    </NoSSR>
                </div>
                <Chip id="60012" type="recommend" title="公益首页-益言堂推荐" groupName="正文" content={comment}>
                    <NewsItemYyt ad={ads.yytAd} />
                </Chip>
                <div className={styles.container}>
                    <NoSSR>
                        <Chip
                            id="30015"
                            type="static"
                            title="公益首页-益影像益播台"
                            groupName="正文"
                            content={yiYingXiang}>
                            <YiYingXiang />
                        </Chip>
                    </NoSSR>
                </div>
                <div className={`${styles.container} clearfix`}>
                    <Chip id="60013" type="recommend" title="公益首页-益影像推荐" groupName="正文" content={photo}>
                        <Photo />
                    </Chip>
                    {/* <NoSSR> */}
                    <Chip id="60014" type="recommend" title="公益首页-益播台推荐" groupName="正文" content={video}>
                        <VideoBox ad={ads.ybtAd} />
                    </Chip>
                    {/* </NoSSR> */}
                </div>
                <div className={styles.ad_m_4}>
                    <Ad content={ads.fullColAd3} />
                </div>
                <div className={styles.container}>
                    <NoSSR>
                        <Chip
                            id="30016"
                            type="static"
                            title="公益首页-行动者联盟"
                            groupName="正文"
                            content={xingDongZhe}>
                            <XingDongZhe />
                        </Chip>
                    </NoSSR>
                </div>
                <Chip
                    id="60015"
                    type="recommend"
                    title="公益首页-行动者联盟推荐"
                    groupName="正文"
                    content={actionLeague}>
                    <ActionLeague ad={ads.xdzlmAd} />
                </Chip>
                <div className={styles.container}>
                    <NoSSR>
                        <Chip id="30017" type="static" title="公益首页-公益先锋" groupName="正文" content={xianFeng}>
                            <XianFeng />
                        </Chip>
                    </NoSSR>
                </div>
                <Chip id="60016" type="recommend" title="公益首页-公益先锋推荐" groupName="正文" content={interview}>
                    <NewsItem />
                </Chip>
                <div className={styles.ad_m_5}>
                    <Ad content={ads.fullColAd4} />
                </div>
                <div className={styles.container}>
                    <NoSSR>
                        <Chip id="30018" type="static" title="公益首页-公益沙龙" groupName="正文" content={shaLong}>
                            <ShaLong />
                        </Chip>
                    </NoSSR>
                </div>
                <Chip id="60017" type="recommend" title="公益首页-公益沙龙推荐" groupName="正文" content={salon}>
                    <NewsItem />
                </Chip>
                <div className={styles.container}>
                    <NoSSR>
                        <Chip id="30019" type="static" title="公益首页-一星一益" groupName="正文" content={yiXing}>
                            <YiXing />
                        </Chip>
                    </NoSSR>
                </div>
                <Chip id="60018" type="recommend" title="公益首页-一星一益推荐" groupName="正文" content={star}>
                    <NewsItemYxyy ad={ads.yxyyAd} />
                </Chip>
                <div className={styles.ad_m_6}>
                    <Ad content={ads.fullColAd5} />
                </div>
                <div className={styles.container}>
                    <NoSSR>
                        <Chip id="30020" type="static" title="公益首页-爱心联盟" groupName="正文" content={lianMeng}>
                            <LianMeng />
                        </Chip>
                    </NoSSR>
                </div>
                <div className={styles.container}>
                    <NoSSR>
                        <Chip id="30021" type="static" title="公益首页-底部合作" groupName="正文" content={league}>
                            <League />
                        </Chip>
                    </NoSSR>
                </div>
                <div className={styles.ad_b}>
                    <Ad content={ads.bottomAd} />
                </div>
            </div>
        );
    }
}

export default errorBoundary(Content);
