import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { Player } from 'ThreeTerminal_pc/video';

/**
 * for this page
 */
// import Loadable from 'react-loadable';
// import LazyLoad from '@ifeng-fe/ui_pc_lazyLoad';
// class PlayerLoading extends React.PureComponent {
//     render() {
//         return (
//             <div style={{ width: '300px', height: '250px', textAlign: 'center', lineHeight: '250px' }}>Loading...</div>
//         );
//     }
// }
// let Player = PlayerLoading;

// const LoadVideoPlayer = Loadable({
//     loader: () =>
//         import('ThreeTerminal_pc/video').then(res => {
//             Player = res.Player;
//         }),
//     loading() {
//         return PlayerLoading;
//     },
//     render(loaded, props) {
//         return <Player {...props} />;
//     },
// });

class Video extends React.PureComponent {
    static propTypes = {
        videoConfig: PropTypes.object,
    };

    state = {
        // showVideo: typeof window !== 'undefined',
        videoConfig: this.props.videoConfig || {},
    };

    componentDidMount = () => {
        // LoadVideoPlayer.preload();
    };

    static getDerivedStateFromProps = (props, state) => {
        let resVideoConfig = {};

        if (props.videoConfig !== state.videoConfig) {
            resVideoConfig = {
                videoConfig: props.videoConfig,
            };
        }

        return {
            ...resVideoConfig,
        };
    };

    render() {
        console.log('Video=', this.props);
        const { videoConfig } = this.state;
        const playermsg = {
            width: 300,
            height: 250,
            autoPlay: false,
            controls: true,
        };

        return (
            videoConfig &&
            Object.keys(videoConfig).length && (
                // <LazyLoad>
                <Player videoConfig={videoConfig} playermsg={playermsg} />
                // </LazyLoad>
            )
        );
    }
}

export default errorBoundary(Video);
