import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NoSSR from 'react-no-ssr';
import { Event } from '@ifeng-fe/ui_base';
import { handleAd } from '@ifeng-fe/container_channel/common/client/winter/utils/handleAd';
/**
 * for this page
 */
import Video from '../../../../components/video';
import { getGongyiVideoData } from '../../../../../services/api';

class VideoBox extends React.PureComponent {
    state = {
        videoIndex: 0,
        data: [],
        videoConfig: {},
    };
    static propTypes = {
        content: PropTypes.array,
        ad: PropTypes.object,
    };

    changeVideo = item => {
        if (item.type === 'video' || item.type === 'miniVideo') {
            this.asyncGetGongyiVideoData(item.base62Id);
        } else if (window) window.location.href = item.url;
    };

    // 获取视频数据
    asyncGetGongyiVideoData = async id => {
        // const { content } = this.props;
        // const keys = [];

        // if (content && content.length > 0) {
        //     content.forEach(item => {
        //         keys.push(item.base62Id);
        //     });
        // }
        // const keysStr = keys.join('&');
        const res = await getGongyiVideoData(id);

        this.setState(
            {
                videoConfig: {},
            },
            () => {
                this.setState({
                    videoConfig: res,
                });
            },
        );
    };

    event = new Event();

    // insertArr 为广告方想要插入的一组位置，replaceArr 为广告方想要替换的一组位置，adFn 为回调方法
    insert = (insertArr, replaceArr, adFn) => {
        if (adFn && typeof adFn === 'function') {
            const { data } = this.state;

            console.log('insertArr', insertArr); // 要插入的数据的索引
            console.log('replaceArr', replaceArr); // 被替换的数据的索引
            const infoData = [...data];
            const refs = [];

            // 广告插入，在指定位置后插入广告位数据
            insertArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 0, { type: 'ad', ref });
            });

            // 广告替换，在指定位置删除原数据，替换为广告位数据
            replaceArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 1, { type: 'ad', ref });
            });

            // 广告数据整合处理完毕后，更新列表数据
            this.setState(
                {
                    data: infoData,
                },
                () => {
                    const dom = {};

                    for (const ref of refs) {
                        dom[ref.index] = ref.ref.current;
                    }

                    adFn(dom);
                },
            );
        }

        return;
    };

    async componentDidMount() {
        this.asyncGetGongyiVideoData(this.props.content && this.props.content[0].base62Id);
        // 插入软广
        try {
            const { ad } = this.props;

            // event 为传入的 event 事件对象，用于广告方绑定事件
            // insert 为我们自定义的广告插入方法
            // const ad = {
            //     data: {},
            //     preload: '',
            //     callback: (data, event, insert) => {
            //         let box1 = document.createElement('div');

            //         box1.innerHTML = 'qqqqqq';
            //         // insert([], [0], function(dom) {
            //         //     console.log(dom)
            //         //     dom[0].appendChild(box1);
            //         // });
            //         event.on('init', data => {
            //             // data 为我们触发方法时传参值
            //             const insertArr = [];

            //             const replaceArr = [0];

            //             insert(insertArr, replaceArr, dom => {
            //                 console.log(dom);
            //                 dom[0].appendChild(box1);
            //             });
            //         });
            //     },
            // };

            const callback = await handleAd(ad);

            callback(ad.data, this.event, this.insert); // 将自定义广告插入方法传入，这样广告方即可调用
        } catch (e) {
            console.error(e);
        }

        try {
            const data = JSON.parse(JSON.stringify(this.props.content));

            if (data) {
                this.setState(
                    {
                        data,
                    },
                    () => {
                        this.adInit(data); // 数据请求完毕，初始化时触发广告位插入方法，data 为可选的传参值
                    },
                );
            }
        } catch (e) {
            console.error(e);
        }
    }

    adInit = data => {
        try {
            this.event.trigger('init', { data }); // 触发广告位插入方法
        } catch (e) {
            console.error(e);
        }
    };

    render() {
        // console.log('VideoBox=', this.props);
        const { content } = this.props;
        const { data, videoIndex, videoConfig } = this.state;
        const playListItem = (item, index) => {
            if (item.type === 'ad') {
                return <div className={styles.playListItem} ref={item.ref} key={-1} />;
            } else {
                return (
                    <div className={index === 0 ? styles.playListItem_1 : styles.playListItem} key={index}>
                        <a
                            href="javascript:void(0);"
                            className={styles.title}
                            onClick={e => this.changeVideo(item)}
                            title={item.title}>
                            {item.title}
                        </a>
                    </div>
                );
            }
        };

        return (
            <div className={styles.VideoBox}>
                <NoSSR>
                    <Video videoConfig={videoConfig} />
                </NoSSR>

                <div className={styles.playList}>
                    {data.map((item, index) => {
                        return playListItem(item, index);
                    })}
                </div>
            </div>
        );
    }
}

export default errorBoundary(VideoBox);
