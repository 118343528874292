import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Search from '@ifeng-fe/ui_pc_search';
import { rel as relText } from '@ifeng-fe/container_channel/common/client/utils/rel';
import NoSSR from 'react-no-ssr';

/**
 * for this page
 */
import Slogan from '../../../components/slogan';
import TopBanner from '../../../components/topBanner';

class GongyiHeader extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        // console.log('gongyiHeader=', this.props);
        const { slogan, topBanner, search } = this.props.content;
        /**
         * 组件分发数据
         */

        return (
            <div className={`${styles.gongyiHeader} clearfix`}>
                <div className={styles.logo}>
                    <a href="http://gongyi.ifeng.com/" target="_blank" rel={relText}>
                        <img
                            src="http://p3.ifengimg.com/37780e23b9ea2d8b/2017/38/logoGY.png"
                            width="161"
                            height="27"
                            alt="凤凰网公益"
                        />
                    </a>
                </div>
                <div className={styles.logo_2}>
                    <Chip id="30009" type="static" title="公益首页-slogan" groupName="头部" content={slogan}>
                        <Slogan />
                    </Chip>
                </div>
                <div className={styles.logo_3}>
                    <Chip id="30010" type="static" title="公益首页-顶部banner" groupName="头部" content={topBanner}>
                        <TopBanner />
                    </Chip>
                </div>
                <div className={styles.navFind}>
                    <NoSSR>
                        <Chip id="10129" type="static" title="通用搜索" groupName="头部" content={search}>
                            <Search />
                        </Chip>
                    </NoSSR>
                </div>
            </div>
        );
    }
}

export default errorBoundary(GongyiHeader);
