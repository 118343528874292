import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel as relText } from '@ifeng-fe/container_channel/common/client/utils/rel';

/**
 * for this page
 */
class UnderNav extends React.PureComponent {
    state = {
        qrCodeIsHide: true,
    };
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        // console.log('UnderNav=', this.props);
        const { content } = this.props;
        /**
         * 组件分发数据
         */

        return (
            <div className={`${styles.underNav} clearfix`}>
                {content.map((item, index) => {
                    if (index === 0) {
                        return (
                            <div className={styles.navItem} key={index}>
                                <a href={item.url} target="_blank" rel={relText} className={styles.active}>
                                    {item.title}
                                </a>
                            </div>
                        );
                    } else {
                        return (
                            <div className={styles.navItem} key={index}>
                                <a href={item.url} target="_blank" rel={relText}>
                                    {item.title}
                                </a>
                            </div>
                        );
                    }
                })}
                <div className={styles.last_nav_logo}>
                    <img src="http://p0.ifengimg.com/fe/gongyi_home_page/images/logo-5_77931508.jpg" alt="" />
                    <div className={styles.qrCode}>
                        <img src="http://p3.ifengimg.com/a/2017/0717/d6a10441b4e82e4size33_w239_h436.png" alt="" />
                    </div>
                </div>
            </div>
        );
    }
}

export default errorBoundary(UnderNav);
