import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { getSurveyData } from '../../../../../services/api';
import { rel as relText } from '@ifeng-fe/container_channel/common/client/utils/rel';
import { classNames } from '@ifeng-fe/container_channel/common/client/winter/utils/classnames';
import errorBoundary from '@ifeng-fe/errorBoundary';

// const letter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'; ie7不兼容通过索引获取字符串中的制定字符
const letter = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'];

class Survey extends PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        result: null,
    };

    async componentDidMount() {
        const { surveyId } = this.props.content;
        const data = await getSurveyData(surveyId);

        if (data.msg === 'ok') {
            this.setState({
                result: data,
            });
        }
    }
    optionView(option) {
        const len = option.length - 1;

        return option.map((item, index) => {
            return (
                <li className={len === index ? null : styles.bline} key={index}>
                    <div className={classNames(styles.num, 'clearfix')}>
                        <span className={styles.letter}>{letter[index]}</span>
                        <span className={styles.line} />
                        <span className={styles.nump}>{item.nump}%</span>
                    </div>
                    <p className={styles.optionTitle}>{item.title}</p>
                </li>
            );
        });
    }
    surveyView(index) {
        const { surveyId } = this.props.content;
        const { result } = this.state;
        const { resultArray } = result.data.result[index - 1];
        const url = `//gongyi.ifeng.com/survey.html#id=${surveyId}`;

        return (
            <div>
                <h4 className={styles.title}>
                    <a href={url} target="_blank" rel={relText}>
                        {resultArray.question}
                    </a>
                </h4>
                <ul className={styles.option}>{this.optionView(resultArray.option)}</ul>
                <div className={styles.tools}>
                    <p>{result.data.count}人参与投票</p>
                    <a className={styles.toupiao} href={url} target="_blank" rel={relText}>
                        我要投票
                    </a>
                </div>
            </div>
        );
    }
    render() {
        // console.log('survey=', this.props);
        const { index } = this.props.content;
        const { result } = this.state;

        return (
            <div className={styles.surveyBox}>
                <div className={styles.survey}>{result ? this.surveyView(index) : null}</div>
            </div>
        );
    }
}

export default errorBoundary(Survey);
