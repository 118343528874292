import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Slides from '@ifeng-fe/ui_pc_slides';
import { rel as relText } from '@ifeng-fe/container_channel/common/client/utils/rel';
import { formatImage } from '@ifeng-fe/container_channel/common/client/winter/utils/formatImg';

import { Event } from '@ifeng-fe/ui_base';
import { handleAd } from '@ifeng-fe/container_channel/common/client/winter/utils/handleAd';

/**
 * for this page
 */

class ActionLeague extends React.PureComponent {
    state = {
        maskIsHide: true,
        data: [],
    };
    static propTypes = {
        content: PropTypes.array,
        ad: PropTypes.object,
    };

    event = new Event();

    // insertArr 为广告方想要插入的一组位置，replaceArr 为广告方想要替换的一组位置，adFn 为回调方法
    insert = (insertArr, replaceArr, adFn) => {
        if (adFn && typeof adFn === 'function') {
            const { data } = this.state;

            console.log('insertArr', insertArr); // 要插入的数据的索引
            console.log('replaceArr', replaceArr); // 被替换的数据的索引
            const infoData = [...data];
            const refs = [];

            // 广告插入，在指定位置后插入广告位数据
            insertArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 0, { type: 'ad', ref });
            });

            // 广告替换，在指定位置删除原数据，替换为广告位数据
            replaceArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 1, { type: 'ad', ref });
            });

            // 广告数据整合处理完毕后，更新列表数据
            this.setState(
                {
                    data: infoData,
                },
                () => {
                    const dom = {};

                    for (const ref of refs) {
                        dom[ref.index] = ref.ref.current;
                    }

                    adFn(dom);
                },
            );
        }

        return;
    };

    async componentDidMount() {
        // 插入软广
        try {
            const { ad } = this.props;

            // const ad = {
            //     data: {},
            //     preload: '',
            //     callback: (data, event, insert) => {
            //         // event 为传入的 event 事件对象，用于广告方绑定事件
            //         // insert 为我们自定义的广告插入方法
            //         event.on('init', data => {
            //             // data 为我们触发方法时传参值
            //             const insertArr = [];

            //             const replaceArr = [3];

            //             insert(insertArr, replaceArr, dom => {});
            //         });
            //     },
            // };

            const callback = await handleAd(ad);

            callback(ad.data, this.event, this.insert); // 将自定义广告插入方法传入，这样广告方即可调用
        } catch (e) {
            console.error(e);
        }

        try {
            const data = this.props.content;

            if (data) {
                this.setState(
                    {
                        data,
                    },
                    () => {
                        this.adInit(data); // 数据请求完毕，初始化时触发广告位插入方法，data 为可选的传参值
                    },
                );
            }
        } catch (e) {
            console.error(e);
        }
    }

    adInit = data => {
        try {
            this.event.trigger('init', { data }); // 触发广告位插入方法
        } catch (e) {
            console.error(e);
        }
    };

    render() {
        // console.log('ActionLeague=', this.props);
        const { content } = this.props;
        const { data } = this.state;
        /**
         * 组件分发数据
         */
        const sliderTmpl = function sliderTmpl(item) {
            if (item.type === 'ad') {
                return <div className={styles.slideItem} ref={item.ref} />;
            } else {
                return (
                    <div className={styles.slideItem}>
                        <div className={styles.mask} />
                        <a
                            className={styles.blockA}
                            href={item.url}
                            target="_blank"
                            alt={item.title}
                            title={item.title}
                            rel={relText}>
                            <img
                                src={item.thumbnail ? formatImage(item.thumbnail, 800, 500) : ''}
                                style={{ width: '100%', height: 500 }}
                            />
                        </a>
                        <div className={styles.text_wrap}>
                            <div className={styles.text_bg} />
                            <div className={styles.text}>
                                <a href={item.url} target="_blank" alt={item.title} title={item.title} rel={relText}>
                                    {item.title}
                                </a>
                            </div>
                            <div className={styles.text_content}>{item.summary}</div>
                        </div>
                    </div>
                );
            }
        };

        const sliderConfig = {
            arrows: true,
            autoplay: true,
            dots: true,
            dotsAction: 'click',
            direction: 'forward',
            pauseOnHover: true,
            autoplayInterval: 5000,
            axis: 'horizonta',
            initialSlide: 0,
            // unitSize: 1000,
            speed: 300,
            infinite: true,
            dotStyle: styles.dot,
            dotCurrentStyle: styles.current,
            sliderTmpl,
        };

        return (
            <div className={styles.ActionLeague}>
                <Slides content={data} config={sliderConfig} />
            </div>
        );
    }
}

export default errorBoundary(ActionLeague);
