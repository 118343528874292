import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import transform from 'chipDataTransform';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Ad from '@ifeng-fe/ui_pc_ad';
import NoSSR from 'react-no-ssr';

/**
 * for this page
 */
import Content from './content';
import GongyiFooter from './gongyiFooter';
import GongyiHeader from './gongyiHeader';
import UpperNav from './upperNav';
import UnderNav from './underNav';

class Layout extends React.PureComponent {
    state = {
        qrCodeIsHide: false,
    };

    static propTypes = {
        content: PropTypes.object,
    };

    async componentDidMount() {
        // 上报首屏时间
        if (window && window.BJ_REPORT) window.BJ_REPORT.firstScreen();
        this.qrCodeShow();
    }

    // 控制二维码的显示隐藏
    qrCodeShow() {
        if (window) {
            if (document.body.clientWidth <= 1520) {
                console.log(document.body.clientWidth);
                this.setState({
                    qrCodeIsHide: true,
                });
            } else {
                this.setState({
                    qrCodeIsHide: false,
                });
                window.onscroll = () => {
                    const scrollTop =
                        document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;

                    if (scrollTop >= 8379) {
                        this.setState({
                            qrCodeIsHide: true,
                        });
                    } else {
                        this.setState({
                            qrCodeIsHide: false,
                        });
                    }
                };
            }
        }
    }

    render() {
        console.log('layout=', this.props);
        /**
         * 组件分发数据
         */
        const {
            slogan,
            topBanner,
            search,
            upperNav,
            underNav,
            topSlide,
            yiGuanZhu,
            news,
            yiChuangXin,
            innovation,
            yiXingDong,
            action,
            survey,
            yiYanTang,
            comment,
            yiYingXiang,
            photo,
            video,
            xingDongZhe,
            actionLeague,
            xianFeng,
            interview,
            shaLong,
            salon,
            yiXing,
            star,
            lianMeng,
            league,
            gongyiFooter,
            qrCode,
        } = this.props.content;

        const header = {
            slogan,
            topBanner,
            search,
        };

        const content = {
            topSlide,
            yiGuanZhu,
            news,
            yiChuangXin,
            innovation,
            yiXingDong,
            action,
            survey,
            yiYanTang,
            comment,
            yiYingXiang,
            photo,
            video,
            xingDongZhe,
            actionLeague,
            xianFeng,
            interview,
            shaLong,
            salon,
            yiXing,
            star,
            lianMeng,
            league,
        };

        const ads = {
            headerAd: this.props.content.headerAd,
            fullColAd1: this.props.content.fullColAd1,
            fullColAd2: this.props.content.fullColAd2,
            fullColAd3: this.props.content.fullColAd3,
            fullColAd4: this.props.content.fullColAd4,
            fullColAd5: this.props.content.fullColAd5,
            rectangle1: this.props.content.rectangle1,
            bottomAd: this.props.content.bottomAd,
            topSlideAd: this.props.content.topSlideAd,
            ygzAd: this.props.content.ygzAd,
            yxdAd: this.props.content.yxdAd,
            yytAd: this.props.content.yytAd,
            ybtAd: this.props.content.ybtAd,
            xdzlmAd: this.props.content.xdzlmAd,
            yxyyAd: this.props.content.yxyyAd,
            popunderAd: this.props.content.popunderAd,
            adCouplet: this.props.content.adCouplet,
        };

        return (
            <div className={styles.layout}>
                {/* 头部广告 */}
                <div className={styles.ad_top}>
                    <Ad content={ads.headerAd} />
                </div>
                <NoSSR>
                    <GongyiHeader content={header} />
                </NoSSR>
                <Chip id="45006" type="struct" title="公益首页-导航一" groupName="导航" content={upperNav}>
                    <UpperNav />
                </Chip>
                <Chip id="45007" type="struct" title="公益首页-导航二" groupName="导航" content={underNav}>
                    <UnderNav />
                </Chip>
                <Content content={content} ads={ads} />
                <NoSSR>
                    <Chip id="30031" type="static" title="公益首页-底部版权" groupName="导航" content={gongyiFooter}>
                        <GongyiFooter />
                    </Chip>
                </NoSSR>
                <Chip id="50031" type="struct" title="公益首页-悬浮二维码" groupName="二维码" content={upperNav}>
                    <div
                        className={this.state.qrCodeIsHide ? styles.hide : styles.qrCode}
                        style={{ width: `${qrCode.width}px` }}>
                        <img src={qrCode.src} title={qrCode.title} />
                    </div>
                </Chip>
                <ChipEdit transform={transform} />
                <div>
                    <Ad content={ads.adCouplet} />
                </div>
                <div>
                    <Ad content={ads.popunderAd} />
                </div>
            </div>
        );
    }
}

export default errorBoundary(Layout);
