import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import transform from 'chipDataTransform';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel as relText } from '@ifeng-fe/container_channel/common/client/utils/rel';

/**
 * for this page
 */

class UpperNav extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        // console.log('upperNav=', this.props);
        const { content } = this.props;
        /**
         * 组件分发数据
         */

        return (
            <div className={`${styles.upperNav} clearfix`}>
                {content.map((item, index) => (
                    <div className={styles.navItem} key={index}>
                        <a href={item.url} target="_blank" rel={relText}>
                            {item.title}
                        </a>
                    </div>
                ))}
            </div>
        );
    }
}

export default errorBoundary(UpperNav);
