import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Slides from '@ifeng-fe/ui_pc_slides';
import { rel as relText } from '@ifeng-fe/container_channel/common/client/utils/rel';
import { formatImage } from '@ifeng-fe/container_channel/common/client/winter/utils/formatImg';

/**
 * for this page
 */

class Innovation extends React.PureComponent {
    state = {
        maskIsHide: true,
    };
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        // console.log('innovation=', this.props);
        const { content } = this.props;
        /**
         * 组件分发数据
         */
        const sliderTmpl = function sliderTmpl(item) {
            return (
                <div className={styles.slideItem}>
                    <div className={styles.mask} />
                    <a
                        className={styles.blockA}
                        href={item.url}
                        target="_blank"
                        alt={item.title}
                        title={item.title}
                        rel={relText}>
                        <img
                            src={item.thumbnail ? formatImage(item.thumbnail, 686, 450) : ''}
                            style={{ width: '100%', height: 450 }}
                        />
                    </a>
                    <div className={styles.text_wrap}>
                        <div className={styles.text_bg} />
                        <div className={styles.text}>
                            <a href={item.url} target="_blank" alt={item.title} title={item.title} rel={relText}>
                                {item.title.length > 28 ? `${item.title.slice(0, 27)}...` : item.title}
                            </a>
                        </div>
                    </div>
                </div>
            );
        };

        const sliderConfig = {
            arrows: false,
            autoplay: true,
            dots: true,
            dotsAction: 'click',
            direction: 'forward',
            pauseOnHover: true,
            autoplayInterval: 5000,
            axis: 'horizonta',
            initialSlide: 0,
            unitSize: 686,
            speed: 300,
            infinite: true,
            dotStyle: styles.dot,
            dotCurrentStyle: styles.current,
            sliderTmpl,
        };

        return (
            <div className={styles.Innovation}>
                <Slides content={content} config={sliderConfig} />
            </div>
        );
    }
}

export default errorBoundary(Innovation);
