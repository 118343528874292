import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { formatImage } from '@ifeng-fe/container_channel/common/client/winter/utils/formatImg';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';

/**
 * for this page
 */

class NewsItem extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        // console.log('newsItem=', this.props);
        const { content } = this.props;
        /**
         * 组件分发数据
         */
        const imgItem = item => {
            return (
                <div className={styles.listItem} key={item.url}>
                    <a href={item.url} alt={item.title} title={item.title} target="_blank" rel={rel}>
                        <img
                            src={item.thumbnail ? formatImage(item.thumbnail, 330, 330) : ''}
                            alt={item.title}
                            title={item.title}
                        />
                        <div className={styles.text}>{item.title}</div>
                    </a>
                </div>
            );
        };

        return (
            <div className={`${styles.NewsItem} clearfix`}>
                {content.slice(0, 3).map(item => {
                    return imgItem(item);
                })}
                <div className={styles.right_img} />
            </div>
        );
    }
}

export default errorBoundary(NewsItem);
