import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { formatImage } from '@ifeng-fe/container_channel/common/client/winter/utils/formatImg';
import { rel as relText } from '@ifeng-fe/container_channel/common/client/utils/rel';

import { Event } from '@ifeng-fe/ui_base';
import { handleAd } from '@ifeng-fe/container_channel/common/client/winter/utils/handleAd';
/**
 * for this page
 */

class Action extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
        ad: PropTypes.object,
    };

    state = {
        data: [],
    };

    event = new Event();

    // insertArr 为广告方想要插入的一组位置，replaceArr 为广告方想要替换的一组位置，adFn 为回调方法
    insert = (insertArr, replaceArr, adFn) => {
        if (adFn && typeof adFn === 'function') {
            const { data } = this.state;

            console.log('insertArr', insertArr); // 要插入的数据的索引
            console.log('replaceArr', replaceArr); // 被替换的数据的索引
            const infoData = [...data];
            const refs = [];

            // 广告插入，在指定位置后插入广告位数据
            insertArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 0, { type: 'ad', ref });
            });

            // 广告替换，在指定位置删除原数据，替换为广告位数据
            replaceArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 1, { type: 'ad', ref });
            });
            // 广告数据整合处理完毕后，更新列表数据
            this.setState(
                {
                    data: infoData,
                },
                () => {
                    const dom = {};

                    for (const ref of refs) {
                        dom[ref.index] = ref.ref.current;
                    }

                    adFn(dom);
                },
            );
        }

        return;
    };

    async componentDidMount() {
        // 插入软广
        try {
            const { ad } = this.props;

            // console.log(ad);

            // const ad = {
            //     data: {},
            //     preload: '',
            //     callback: (data, event, insert) => {
            //         let box1 = document.createElement('div');

            //         box1.innerHTML = 'qqqqqq';
            //         // insert([], [0], function(dom) {
            //         //     console.log(dom)
            //         //     dom[0].appendChild(box1);
            //         // });
            //         event.on('init', data => {
            //             // data 为我们触发方法时传参值
            //             const insertArr = [];

            //             const replaceArr = [0];

            //             insert(insertArr, replaceArr, dom => {
            //                 console.log(dom);
            //                 dom[0].appendChild(box1);
            //             });
            //         });
            //     },
            // };

            const callback = await handleAd(ad);

            callback(ad.data, this.event, this.insert); // 将自定义广告插入方法传入，这样广告方即可调用
        } catch (e) {
            console.error(e);
        }

        try {
            // const data = JSON.parse(JSON.stringify(this.props.content));
            const data = this.props.content.slice(0, 4);

            if (data) {
                this.setState(
                    {
                        data,
                    },
                    () => {
                        this.adInit(data); // 数据请求完毕，初始化时触发广告位插入方法，data 为可选的传参值
                    },
                );
            }
        } catch (e) {
            console.error(e);
        }
    }

    adInit = data => {
        try {
            this.event.trigger('init', { data }); // 触发广告位插入方法
        } catch (e) {
            console.error(e);
        }
    };

    render() {
        // console.log('Action=', this.props);
        const { content } = this.props;
        const { data } = this.state;

        // console.log(data);
        // const newData = JSON.parse(JSON.stringify(data));
        /**
         * 组件分发数据
         */
        const imgItem = (item, index) => {
            if (item.type === 'ad') {
                return <div className={styles.listItem} ref={item.ref} key={-1} />;
            } else {
                return (
                    <div className={styles.listItem} key={index}>
                        <a href={item.url} alt={item.title} title={item.title} target="_blank" rel={relText}>
                            <img
                                src={item.thumbnail ? formatImage(item.thumbnail, 338, 338) : ''}
                                alt={item.title}
                                title={item.title}
                            />
                            <div className={styles.text}>{item.title}</div>
                        </a>
                    </div>
                );
            }
        };

        return (
            <div className={`${styles.Action} clearfix`}>
                {data.map((item, index) => {
                    return imgItem(item, index);
                })}
                <div className={styles.right_img} />
            </div>
        );
    }
}

export default errorBoundary(Action);
